export function getWindowHeight() {
    return typeof window !== `undefined` ? window.innerHeight : 0;
}

export function getWindowWidth() {
    return typeof window !== `undefined` ? window.innerWidth : 0;
}

export function isWindowActive() {
    return typeof window !== `undefined`;
}