import React from 'react';
import PropTypes from 'prop-types';
import anime from "animejs";
import {isWindowActive} from "../../helpers/window";

const PREFIX = '__anime__';

export default class Anime extends React.PureComponent {
    static defaultProps = {
        duration: 1000,
        easing: 'linear'
    }

    static propTypes = {
        callbackMount: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.targets = [];
        this.targetRefs = [];
        this.anime = null;

        if (isWindowActive()) {
            window.addEventListener("resize", () => {
                this.createAnime();
            });
        }
    }

    componentDidMount() {
        this.createAnime();
    }

    componentDidUpdate() {
        this.createAnime();
    }

    createAnime() {
        if (!isWindowActive()) {
           return;
        }

        let data = {};
        if (typeof this.props.callbackMount === 'function') {
            data = this.props.callbackMount();
        }
        let props = {...this.props, ...data};

        if (this.targets.length > 0 && this.anime !== undefined) {
            anime.remove(this.targets);
        }

        this.targets = [];
        for (let ref of this.targetRefs) {
            if (ref.current) {
                this.targets.push(ref.current);
            }
        }

        let animeProps = {...props, targets: this.targets};
        animeProps = this.deleteUnnecessaryPropsForAnime(animeProps)
        this.anime = anime(animeProps);
    };

    deleteUnnecessaryPropsForAnime(animeProps) {
        delete animeProps.children;
        delete animeProps.style;
        delete animeProps.callbackMount
        return animeProps;
    }

    render() {
        let children = this.props.children;
        let refs = this.targetRefs;
        if (!Array.isArray(children)) children = [children];
        return (
            <React.Fragment>
                {children.map((child, i) => {
                    refs.push(React.createRef());
                    const style = {...this.props.style}
                    return (
                        <div ref={refs[refs.length - 1]} key={`${PREFIX}${i}`} style={style}>
                            {child}
                        </div>
                    );
                })}
            </React.Fragment>
        );
    }
}
