import React from "react";
import classNames from "classnames";
import styles from "./styles/Header.module.scss";
import logo from "../../static/images/logo.svg";

class Header extends React.PureComponent {
    render() {
        return (
            <div className={classNames("site--padding", styles.header__container)}>
                <div className="col-12">
                    <img src={logo} alt="logo" className={classNames(styles.header__logo)}/>
                </div>
            </div>
        )
    }
}

export default Header;