import React from "react";
import classNames from "classnames";
import ContactForm from "./ContactForm";
import styles from "./styles/Contact.module.scss"

class Contact extends React.PureComponent {
    static initialState = {
        mail: '',
        status: '',
        send: false
    }

    constructor(props) {
        super(props);
        this.state = Contact.initialState;
    }

    submitForm = (ev) => {
        ev.preventDefault();
        if (this.state.send || !this.state.mail) {
            return;
        }
        this.setState({send: true});

        const form = ev.target;
        const data = new FormData();
        data.append('email', this.state.mail);

        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                this.setState({...Contact.initialState,  status: "SUCCESS", send: true });
            } else {
                this.setState({ status: "ERROR", send: false });
            }
        };
        xhr.send(data);
    }

    render() {
        return (
            <div name="contact" className={classNames("site--padding", styles.contactContainer)}>
                <div className="col-12">
                    <header className={classNames(styles.contactHead)}>
                        <h2>Let's discover your needs, together.</h2>
                        <p>Book a free 20 minute call with us to find out your best next step.</p>
                    </header>
                    <ContactForm
                        submitForm={this.submitForm}
                        data={this.state}
                        onChange={(e, name) => {this.setState({[name]: e.target.value})}}
                    />
                </div>
            </div>
        )
    }
}

export default Contact;