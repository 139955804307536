import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import styles from './styles/CookiesInfo.module.scss'

const CookiesInfo = () => (
    <div className={styles.cookiesInfo}>
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="moonite-cookies"
            buttonStyle={{
                color: "#000",
                fontSize: "13px",
                backgroundColor: '#fff',
                fontFamily: 'dunbar-tall',
                margin: '15px 55px',
                padding: '7px 35px',
                borderRadius: '33px'
            }}
            expires={150}
            contentClasses={styles.text}
            containerClasses={styles.container}
        > 
            This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
            {/* <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="moonite-cookies"
                expires={10}
                style={{
                    color: '#fff',
                    fontFamily: 'dunbar-tall',
                    backgroundColor: 'rgb(34, 63, 103, 0.4)',
                    width: 'auto',
                    margin: '2rem',
                    justifyContent: 'center',
                    textAlign: 'center',
                    borderRadius: '33px',
                    padding: '1rem 1rem'
                }}
                buttonClasses={styles.button}
                contentClasses={styles.text}
            > 
            This website uses cookies to enhance the user experience.{" "}
        </CookieConsent> */}
                {/* <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="moonite-cookies"
                expires={10}
                // style={{
                //     color: '#fff',
                //     fontFamily: 'dunbar-tall',
                //     backgroundColor: 'rgb(34, 63, 103, 0.8)',
                //     width: 'auto',
                //     margin: '2rem',
                //     justifyContent: 'center',
                //     textAlign: 'center',
                //     borderRadius: '33px',
                //     padding: '1rem 1rem'
                // }}
                buttonClasses={styles.button}
                contentClasses={styles.text3}
                containerClasses={styles.test3}
            > 
            This website uses cookies to enhance the user experience.{" "}
        </CookieConsent> */}
  </div>
);

export default CookiesInfo;