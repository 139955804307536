import React from "react";
import classNames from "classnames";
import planet from "../../../static/images/moon.png";
import styles from "./styles/LocationSection.module.scss";
import Contact from "../../Contact";

class LocationSection extends React.PureComponent {
    render() {
        return (
            <div className={classNames("container-fluid", styles.locationSection__container)}>
                <div className="row">
                    <div className="site--padding">
                        <div className={classNames("col-12", styles.locationSection__head)}>
                            <span className="font--2 font--2__weight--3">we're from</span>
                            <span className="font--2 font--2__weight--4">Earth</span>
                            <span className="font--1 font--1__weight--2">Located in Poland.</span>
                            <span className="font--1 font--1__weight--2">Working remotely.</span>
                            <span className="font--1 font--1__weight--2">Available around the World.</span>
                            <div className={classNames(styles.locationSection__markerCircle)}></div>
                        </div>
                    </div>

                    <div className={classNames(styles.locationSectionPlanet__container)}>
                        <div className={classNames(styles.locationSectionPlanet)}>
                            <img alt="moon" src={planet}/>
                        </div>
                    </div>

                    <div className={classNames(styles.locationSectionEarth__background)}></div>
                    <Contact/>
                </div>
            </div>
        )
    }
}

export default LocationSection;