import React from "react";
import {Helmet} from "react-helmet";
import ScrollMagic from 'scrollmagic'
import Header from "../components/Header";
import HeroSection from "../components/sections2/HeroSection";
import ProcessSection from "../components/sections2/ProcessSection";
import LocationSection from "../components/sections2/LocationSection";
import Footer from "../components/Footer/Footer";
import CookiesInfo from "../components/CookiesInfo/CookiesInfo";
import ScrollMagicSingleton from "../static/ScrollMagicSingleton";
import {isWindowActive} from "../helpers/window";
import {MOONITE_SITE_READY_EVENT} from "../components/constants";

class IndexPage extends React.PureComponent {
    constructor(props) {
        super(props);
        if (isWindowActive()) {
            const scrollMagic = new ScrollMagicSingleton();
            scrollMagic.setScroll(new ScrollMagic.Controller({
                globalSceneOptions: {
                    triggerHook: 0
                }
            }));
        }
    }

    componentDidMount() {
        if (isWindowActive()) {
            const event = new Event(MOONITE_SITE_READY_EVENT);
            window.dispatchEvent(event);
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>moonite</title>
                </Helmet>
                <div className="site--background">
                    <Header/>
                    <HeroSection/>
                    <ProcessSection/>
                    <LocationSection/>
                    <Footer/>
                    <CookiesInfo />
                </div>
            </>
        )
    }
}

export default IndexPage;
