import React from "react";
import {Link} from "react-scroll";
import classNames from "classnames";
import styles from "./styles/HeroSection.module.scss";
import Anime from "../../animations/Anime";
import ScrollAnime from "../../animations/ScrollAnime";
import {getRelativePosition} from "../../../helpers/position";
import {getWindowHeight} from "../../../helpers/window";

class HeroSection extends React.PureComponent {
    callbackForScrollAnime = (scrollAnime, sectionRef) => {
        const { current } = sectionRef;
        scrollAnime.scene.offset(getRelativePosition(current))
        const item = document.getElementById('heroSection_discover');
        const finalPosition = getRelativePosition(document.getElementById('processInfo_discover'));
        const duration = finalPosition - getWindowHeight() + item.getBoundingClientRect().height;
        scrollAnime.scene.duration(duration);
    }

    callbackForAnime = (processId, heroId) => {
        return {
            translateY: [
                '0px',
                getRelativePosition(document.getElementById(processId))
                - getRelativePosition(document.getElementById(heroId))
            ]
        }
    }

    render() {
        const sectionRef = React.createRef();
        return (
            <div ref={sectionRef} className={classNames('container-fluid')}>
                <div className={classNames('row site--padding', styles.heroSection__container)}>
                    <div className="col-12 col-lg-8 col-xl-6" style={{zIndex: 1}}>
                        <h1 className={classNames('font--2 font--2__weight--3', styles.heroSection__head)}>
                            <div id="heroSection_discover">
                            <ScrollAnime
                                name="Discover"
                                duration={800}
                                offsetCallback={(scrollAnime) => this.callbackForScrollAnime(scrollAnime, sectionRef)}
                            >
                                <Anime
                                    callbackMount={() => this.callbackForAnime('processInfo_discover', 'heroSection_discover')}
                                >
                                    Discover.
                                </Anime>
                            </ScrollAnime>
                            </div>
                            <div id="heroSection_design">
                            <ScrollAnime
                                name="Discover"
                                duration={800}
                                offsetCallback={(scrollAnime) => this.callbackForScrollAnime(scrollAnime, sectionRef)}
                            >
                                <Anime
                                    callbackMount={() => this.callbackForAnime('processInfo_design', 'heroSection_design')}
                                >
                                    Design.
                                </Anime>
                            </ScrollAnime>
                            </div>
                            <div id="heroSection_deliver">
                            <ScrollAnime
                                name="Discover"
                                duration={800}
                                offsetCallback={(scrollAnime) => this.callbackForScrollAnime(scrollAnime, sectionRef)}
                            >
                                <Anime
                                    callbackMount={() => this.callbackForAnime('processInfo_deliver', 'heroSection_deliver')}
                                >
                                    Deliver.
                                </Anime>
                            </ScrollAnime>
                            </div>
                        </h1>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-6">
                        <div className={classNames("row", styles.heroSection__info__container)}>
                            <ScrollAnime
                                name="CSS"
                                duration={800}
                                offsetCallback={(scrollAnime) => this.callbackForScrollAnime(scrollAnime, sectionRef)}
                            >
                                <Anime
                                    opacity={[1, 0]}
                                    easing='easeOutCirc'
                                >
                                    <div className={classNames(styles.heroSection__info)}>
                                <div className={classNames("col-12", styles.heroSection__info__desc)}>
                                    We help companies uncover true needs, design the experience and build the
                                    solution.
                                </div>
                                <div className="col-12">
                                    <Link
                                        smooth={true}
                                        duration={1500}
                                        delay={200}
                                        to="contact"
                                        spy={true}
                                        className={classNames("btn btn--primary", styles.heroSection__info__buttonContact)}
                                    >
                                        Discover your needs
                                    </Link>
                                    <Link
                                        to="process"
                                        spy={true}
                                        smooth={true}
                                        duration={1500}
                                        delay={200}
                                        offset={-130}
                                        className="btn btn--link"
                                    >
                                        Check our process
                                    </Link>
                                </div>
                            </div>
                                </Anime>
                            </ScrollAnime>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeroSection;