import React from "react";
import classNames from "classnames";
import styles from "./styles/ProcessStyless.module.scss";
import ProcessSectionInfo from "./ProcessSectionInfo";
import {getRelativePosition} from "../../../helpers/position";
import Anime from "../../animations/Anime";
import ScrollAnime from "../../animations/ScrollAnime";
import {getWindowHeight, getWindowWidth} from "../../../helpers/window";

class ProcessSection extends React.PureComponent {
    our = {
        translateX: ['-100%', '0%']
    }
    ourLg = {
        translateX: ['100%', '0%']
    }

    getOurAnime = () => {
        if (getWindowWidth() < 992) {
            return this.our;
        }
        return this.ourLg;
    }

    callbackForScrollAnime = (scrollAnime, ref) => {
        const {current} = ref;
        const a = document.getElementById('heroSection_discover');
        const finalPosition = getRelativePosition(document.getElementById('processInfo_discover'));
        const duration = finalPosition - getWindowHeight();
        scrollAnime.scene.offset(
            (getRelativePosition(current) + current.getBoundingClientRect().height) * duration / finalPosition + current.getBoundingClientRect().height * 0.1
        );
        scrollAnime.scene.duration(a.getBoundingClientRect().height);
    }

    render() {
        const ourRef = React.createRef();
        const proRef = React.createRef();
        const cessRef = React.createRef();
        const processRef = React.createRef();
        return (
            <div className={classNames("container-fluid", styles.processSection__background)}>
                <div className={classNames("row", styles.processSection__container)}>
                    <div name="process"
                         className={classNames("font--2 font--2__weight--4", styles.processSection__head)}>
                        <ScrollAnime
                            name="Our"
                            offsetCallback={(scrollAnime) => this.callbackForScrollAnime(scrollAnime, ourRef)}
                        >
                            <Anime
                                callbackMount={() => this.getOurAnime()}
                            >
                                <span ref={ourRef}
                                      className={classNames("d-block", styles.processSection__headItemFirst)}>Our</span>
                            </Anime>
                        </ScrollAnime>
                        <ScrollAnime
                            name="PRO"
                            offsetCallback={(scrollAnime) => this.callbackForScrollAnime(scrollAnime, proRef)}
                        >
                            <Anime
                                translateX={['-100%', '0%']}
                            >
                                <span ref={proRef}
                                      className={classNames("d-lg-none d-block", styles.processSection__headItemSecond)}>pro</span>
                            </Anime>
                        </ScrollAnime>
                        <ScrollAnime
                            name="CSS"
                            offsetCallback={(scrollAnime) => this.callbackForScrollAnime(scrollAnime, cessRef)}
                        >
                            <Anime
                                translateX={['100%', '0%']}
                            >
                                <span ref={cessRef}
                                      className={classNames("d-lg-none d-block", styles.processSection__headItemSecond)}>cess</span>
                            </Anime>
                        </ScrollAnime>
                        <ScrollAnime
                            name="process"
                            offsetCallback={(scrollAnime) => this.callbackForScrollAnime(scrollAnime, processRef)}
                        >
                            <Anime
                                translateX={['100%', '0%']}
                            >
                                <span ref={processRef}
                            className={classNames("d-none d-lg-block", styles.processSection__headItemSecond)}>process</span>
                            </Anime>
                        </ScrollAnime>
                        </div>
                </div>
                <div className={classNames("row site--padding", styles.processSectionInfo__containers)}>
                    <ProcessSectionInfo
                        id="processInfo_discover"
                        head="Discover."
                        title='true needs'
                        description='Most of the people talk solutions – we talk needs. We support it with data.'/>
                    <ProcessSectionInfo
                        id="processInfo_design"
                        head="Design."
                        title='the experience'
                        description='Great one comes from combining creativity with finding the path to users’ success.'/>
                    <ProcessSectionInfo
                        id="processInfo_deliver"
                        head="Deliver."
                        title='the solution'
                        description='All the efforts goes to transfer the experience into the working solution. It all becomes real.'/>
                </div>
            </div>
        )
    }
}

export default ProcessSection;