import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import styles from "./styles/ProcessStyless.module.scss";
import {getRelativePosition} from "../../../helpers/position";
import Anime from "../../animations/Anime";
import ScrollAnime from "../../animations/ScrollAnime";
import {getWindowHeight} from "../../../helpers/window";

class ProcessSectionInfo extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string,
        head: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }

    render() {
        const ref = React.createRef();
        const refHead = React.createRef();
        return (
            <div ref={ref} className={classNames("col-12", styles.processSectionInfo__container)}>

                <ScrollAnime
                    name={this.props.id}
                    offsetCallback={function (scrollAnime) {
                        const {current} = ref;
                        const currentHead = refHead.current;

                        scrollAnime.scene.offset(
                            getRelativePosition(current) - getWindowHeight() + currentHead.getBoundingClientRect().height
                        );
                        scrollAnime.scene.duration(current.getBoundingClientRect().height);
                    }}
                >
                    <Anime
                        opacity={[0, 1]}
                    >
                        <h2 ref={refHead} id={this.props.id}
                            className={classNames("font--2", styles.processSectionInfo__head)}>{this.props.head}</h2>
                        <span
                            className={classNames("d-block font--2 font--2__weight--3", styles.processSectionInfo__title)}>{this.props.title}</span>
                        <span
                            className={classNames("font--1 font--1__weight--2", styles.processSectionInfo__description)}>{this.props.description}</span>
                    </Anime>
                </ScrollAnime>
            </div>
        )
    }
}

export default ProcessSectionInfo;