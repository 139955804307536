import React from "react";
import classNames from "classnames";
import styles from './styles/Footer.module.scss'

const Footer = () => (
    <footer>
        <div className={classNames(styles.footer)}>
            <div>Oh! By the way, we did this site.</div>
            <div className="font--1__weight--2">{new Date().getFullYear()}</div>
        </div>
    </footer>
);

export default Footer;